import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { CookiesProvider } from "react-cookie";

import { store, persistor } from "./store";
import { GlobalStyles } from "./theme/globalStyles";
import GlobalConfig from "./modules/GlobalConfig";

import reportWebVitals from "./reportWebVitals";
import SessionProvider from "./providers/SessionProvider";
import SettingsProvider from "./providers/SettingsProvider";
import GtmProvider from "./providers/GtmProvider";
import IntercomProvider from "./providers/IntercomProvider";
import LoadingModalProvider from "./providers/LoadingModalProvider";
import AffiliateProvider from "./providers/AffiliateProvider";
import ThemeProvider from "./providers/ThemeProvider";

import MetaPixel from "./utils/meta/MetaPixel";

import AppRouter from "./router";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <MetaPixel />
      <PersistGate persistor={persistor}>
        <GtmProvider>
          <ThemeProvider>
            <GlobalStyles />
            <GlobalConfig />

            <SettingsProvider>
              <SessionProvider>
                <IntercomProvider>
                  <LoadingModalProvider>
                    <CookiesProvider>
                      <AffiliateProvider>
                        <AppRouter />
                      </AffiliateProvider>
                    </CookiesProvider>
                  </LoadingModalProvider>
                </IntercomProvider>
              </SessionProvider>
            </SettingsProvider>
          </ThemeProvider>
        </GtmProvider>
      </PersistGate>
    </Provider>
  </BrowserRouter>,
);

reportWebVitals();
