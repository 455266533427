import React, { memo, useState } from "react";

import { ReactComponent as FeaturedNewTools } from "../../../assets/images/FeaturedNewTools.svg";

import { Container, Wrapper, StyledLink } from "./styles";
import ModalCarts from "../../ModalCarts";

const TopMessage = () => {
  const [openModal, setOpenModal] = useState(false);
  const onCloseModal = () => setOpenModal((prev) => !prev);
  const handleClick = () => {
    onCloseModal();
  };

  return (
    <>
      <Container>
        <Wrapper>
          You are currently on the alpha version of our brand-new migration
          tool, you will get a hot &#128293; 30% discount for regular
          migrations.
          <StyledLink onClick={handleClick}>Learn more</StyledLink>
        </Wrapper>
      </Container>

      {openModal && (
        <ModalCarts
          open={openModal}
          title="New migration tool"
          description="We have created from scratch our brand new migration tool, it’s currently on the alpha stage. We opened it for our loyal customers, and give discount for regular automatic migrations (it doesn’t include packages)."
          logo={<FeaturedNewTools />}
          onCancel={onCloseModal}
        />
      )}
    </>
  );
};

export default memo(TopMessage);
